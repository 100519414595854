import React from "react";
import rehypeReact from "rehype-react";
import { graphql } from "gatsby";

import Section from "../../../components/ui-kit/base/section";
import Banner from "../../../components/ui-kit/base/banner";
import AccentContainer from "../../../components/ui-kit/base/accent-container";
import Cta from "../../../components/ui-kit/base/cta";
import AccentText from "../../../components/ui-kit/base/accent-text";
import TextColumn from "../../../components/ui-kit/base/text-column";
import Grid from "../../../components/ui-kit/base/grid";
import Icon from "../../../components/ui-kit/base/icon";
import OurServiceCard from "../../../components/ui-kit/our-service-card";
import OurServicesGrid from "../../../components/ui-kit/our-services-grid";
import OutstandingServicesColumn from "../../../components/ui-kit/outstanding-services-column";
import OutstandingService from "../../../components/ui-kit/outstanding-service";
import SectionCta from "../../../components/ui-kit/section-cta";
import MainBanner from "../../../components/ui-kit/main-banner";
import Advantage from "../../../components/ui-kit/advantage";
import AddonCreateStep from "../../../components/ui-kit/addon-create-step";
import CtaStep from "../../../components/ui-kit/cta-step";
import SuccessStory from "../../../components/ui-kit/success-story";
import Faq from "../../../components/ui-kit/faq";
import PageLayout from "../../../components/ui-kit/page-layout";
import HeroSection from "../../../components/ui-kit/hero-section";
import ExternalLink from "../../../components/ui-kit/base/external-link";
import ImagesProvider from "../../../components/ui-kit/base/images-provider";
import Layout from "../../../layouts";

const landingRrenderToolkit = {
  section: Section,
  "section-hero": HeroSection,
  "banner-tag": Banner.Tag,
  "banner-title": Banner.Title,
  "banner-text": Banner.Content,
  "banner-image": Banner.Image,
  advantage: Advantage,
  "advantage-icon": TextColumn.Icon,
  "advantage-title": TextColumn.Title,
  "advantage-text": TextColumn.Text,
  "accent-container": AccentContainer,
  cta: Cta,
  "section-cta": SectionCta,
  "cta-title": Cta.Title,
  "cta-text": Cta.Text,
  "cta-action": Cta.Action,
  "accent-text": AccentText,
  "our-service-card": OurServiceCard,
  "our-services-grid": OurServicesGrid,
  "outstanding-services-column": OutstandingServicesColumn,
  "outstanding-service": OutstandingService,
  "services-section-grid": Grid,
  "main-banner": MainBanner,
  "advantages-grid": Grid,
  "addon-create-steps-grid": Grid,
  "addon-create-step": AddonCreateStep,
  "cta-step": CtaStep,
  "success-stories-grid": Grid,
  "success-story": SuccessStory,
  "success-story-title": SuccessStory.Title,
  "success-story-text": SuccessStory.Text,
  "success-story-image": SuccessStory.Image,
  icon: Icon,
  faq: Faq,
  "faq-item": Faq.Item,
  "external-link": ExternalLink,
};

const astRenderer = new rehypeReact({
  createElement: React.createElement,
  components: landingRrenderToolkit,
}).Compiler;

const NewLandingPage = props => {
  const {
    data: { markdownRemark, images },
  } = props;

  const seo = markdownRemark.frontmatter.seo?.[0] ?? {};

  return (
    <Layout
      title={seo.title}
      description={seo.description}
      link={seo.link}
      image={seo.image}
    >
      <PageLayout>
        <ImagesProvider pageImages={images}>
          {astRenderer(markdownRemark.htmlAst)}
        </ImagesProvider>
      </PageLayout>
    </Layout>
  );
};

export default NewLandingPage;

export const query = graphql`
  query($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      html
      htmlAst
      frontmatter {
        seo {
          title
          description
          link
          image
        }
      }
    }
    images: allFile(filter: { relativePath: { glob: "images/landing/**" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(
            width: 800
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
  }
`;
